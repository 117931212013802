<template>
  <div>
    <v-simple-table class="table-padding-2">
      <thead class="v-data-table-header">
        <tr>
          <th role="columnheader" class="text-center"></th>
          <th role="columnheader">
            <SelectWarehouse
              :label="$t('labels.warehouse')"
              :placeholder="$t('labels.warehouse')"
              name="id_warehouse"
              sort-name="warehouse_code"
              has-sort
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader">
            <InputFilter
              :label="$t('labels.tracking')"
              :placeholder="$t('labels.tracking')"
              name="tracking_id"
              sort-name="tracking_id"
              has-sort
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader">
            <DateRangeFilter
              :label="$t('labels.created_at')"
              :placeholder="$t('labels.created_at')"
              sort-name="created_at"
              name="created_at"
              has-sort
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader">
            <SelectEmployee
              :label="$t('labels.employee_create')"
              :placeholder="$t('labels.employee_create')"
              name="id_employee_create"
              sort-name="employee_create_name"
              has-sort
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader">
            <InputFilterFromTo
              :label="$t('labels.quantity')"
              :placeholder="$t('labels.quantity')"
              name="quantity"
              sort-name="quantity"
              has-sort
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader">
            <InputFilter
              :label="$t('labels.supplier')"
              :placeholder="$t('labels.supplier')"
              name="supplier_name"
              sort-name="supplier_name"
              has-sort
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <SelectEmployee
              :label="$t('labels.employee_receipt_2')"
              :placeholder="$t('labels.employee_receipt_2')"
              name="id_employee_receipt"
              sort-name="employee_receipt_name"
              has-sort
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <SelectFilter
              :options="imageOptions"
              :label="$t('labels.report_image')"
              :placeholder="$t('labels.report_image')"
              name="has_image"
              sort-name="has_image"
              has-sort
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, key) in items"
          :key="`p_${key}_${item.id}`"
          class="text-center"
        >
          <td>
            <ExportDefectiveUploadImage :item="item" @refreshData="getList" />
          </td>
          <td>{{ item.warehouse_code }}</td>
          <td>{{ item.tracking_id }}</td>
          <td>{{ formatDateTime(item.created_at) }}</td>
          <td>{{ item.employee_create_name }}</td>
          <td>{{ formatNumber(item.quantity) }}</td>
          <td>{{ item.supplier_name }}</td>
          <td>{{ item.employee_receipt_name }}</td>
          <td>
            <ExportDefectiveImage :item="item" />
          </td>
          <td>
            <v-btn
              small
              color="success"
              outlined
              @click="downloadExportFile(item)"
            >
              <img
                src="@/assets/common/download.png"
                style="height: 24px"
                alt=""
              />
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" class="text-right">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { IMAGE_OPTIONS } from "@/libs/const";
import {
  debounce,
  formatDateTime,
  formatNumber,
  downloadExcelFile,
} from "@/libs/helpers";

export default {
  name: "Fast",
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    SelectEmployee: () => import("@/components/table/SelectEmployee"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    ExportDefectiveUploadImage: () =>
      import("@/components/goods/ExportDefectiveUploadImage"),
    ExportDefectiveImage: () =>
      import("@/components/goods/ExportDefectiveImage"),
  },
  props: {},
  data: () => ({
    page: 1,
    totalPage: 1,
    items: [],
    filters: {},
    imageOptions: [...IMAGE_OPTIONS],
  }),
  computed: {},
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    formatDateTime,
    formatNumber,
    downloadExcelFile,
    getList: debounce(function () {
      httpClient
        .post("/prepair-goods/v1/goods-export-defective-history", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 1000),
    downloadExportFile(item) {
      this.downloadExcelFile(
        "/prepair-goods/v1/goods-export-defective-excel",
        {
          id: item.id,
          type_to_text: {
            1: this.$t("labels.export_return_supplier"),
            2: this.$t("labels.destroy_defective"),
            3: this.$t("labels.fix_defective"),
            4: this.$t("labels.clearance"),
          },
        },
        "A4_ban_ban_xuat_hang_loi.xlsx"
      );
      //goods-export-defective-save-images
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
  },
};
</script>

<style scoped></style>
